// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadingAnimation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #FFFFFF; 
    z-index: 999;
      }
  
  .loader {
    border: 16px solid #f3f3f3; 
    border-top: 16px solid #0b2948; 
    border-radius: 50%;
    width: 200px;
    height: 200px;
    /* z-index: 999; */
    animation: spin 2s linear infinite;   
    /* position: "absolute"; */

  }
  

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  

 
  `, "",{"version":3,"sources":["webpack://./src/Components/Loading/loading.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,YAAY;MACV;;EAEJ;IACE,0BAA0B;IAC1B,8BAA8B;IAC9B,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,kCAAkC;IAClC,0BAA0B;;EAE5B;;;EAGA;IACE,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC","sourcesContent":[".loadingAnimation {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n    background-color: #FFFFFF; \r\n    z-index: 999;\r\n      }\r\n  \r\n  .loader {\r\n    border: 16px solid #f3f3f3; \r\n    border-top: 16px solid #0b2948; \r\n    border-radius: 50%;\r\n    width: 200px;\r\n    height: 200px;\r\n    /* z-index: 999; */\r\n    animation: spin 2s linear infinite;   \r\n    /* position: \"absolute\"; */\r\n\r\n  }\r\n  \r\n\r\n  @keyframes spin {\r\n    0% { transform: rotate(0deg); }\r\n    100% { transform: rotate(360deg); }\r\n  }\r\n  \r\n\r\n \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
