// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination{
    justify-content: center;
}

.pagination-head{
    display: flex;
    flex: 1 1;
    flex-direction: row;
    flex-wrap: nowrap;
}

.pagination-detail{
    display: flex;
    /* flex: 1; */
    flex-wrap: nowrap;
    padding-right: 10px
}`, "",{"version":3,"sources":["webpack://./src/Components/Pagination.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,SAAO;IACP,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,iBAAiB;IACjB;AACJ","sourcesContent":[".pagination{\r\n    justify-content: center;\r\n}\r\n\r\n.pagination-head{\r\n    display: flex;\r\n    flex: 1;\r\n    flex-direction: row;\r\n    flex-wrap: nowrap;\r\n}\r\n\r\n.pagination-detail{\r\n    display: flex;\r\n    /* flex: 1; */\r\n    flex-wrap: nowrap;\r\n    padding-right: 10px\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
